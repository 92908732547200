<script lang="ts">
	import { page } from '$app/stores';
	import { routeSteps } from '$lib/utils/routing';
	import { isPersonalVerification, isAddressVerification } from '$lib/stores/generalStore';
	import { _ } from 'svelte-i18n';

	let currentStep = 0;
	let currentStepName = '';

	$: {
		const path = $page.url.pathname;
		currentStep = routeSteps[path] || 0;
		currentStepName = $page.url.pathname;
	}
</script>

<div class="stepper-container flex w-full h-fit">
	{#if $isAddressVerification}
		<ul class="steps flex w-full">
			<li data-content="1" class={currentStep >= 1 ? 'step step-primary' : 'step'}>
				{$_('stepper.address.address')}
			</li>
			<li data-content="2" class={currentStep >= 2 ? 'step step-primary' : 'step'}>
				{$_('stepper.address.addressProof')}
			</li>
			<li data-content="3" class={currentStep >= 3 ? 'step step-primary' : 'step'}>
				{$_('stepper.address.send')}
			</li>
		</ul>
	{:else if $isPersonalVerification}
		<ul class="steps flex w-full">
			<li data-content="1" class={currentStep >= 4 ? 'step step-primary' : 'step'}>
				{$_('stepper.personal.personal')}
			</li>
			<li data-content="2" class={currentStep >= 5 ? 'step step-primary' : 'step'}>
				{$_('stepper.personal.id')}
			</li>
			<li data-content="3" class={currentStep >= 6 ? 'step step-primary' : 'step'}>
				{$_('stepper.personal.idBack')}
			</li>
			<li data-content="4" class={currentStep >= 7 ? 'step step-primary' : 'step'}>
				{$_('stepper.personal.send')}
			</li>
		</ul>
	{/if}
</div>

<style lang="scss">
  .step {
    gap: 0;
    &::after {
      height: 1.75rem;
      width: 1.75rem;
    }
    &::before {
      height: 0.25rem;
    }
  }
  li {
    font-size: 0.8rem;
    font-weight: normal;
  }
</style>