<script lang="ts">
	import BackButton from '$lib/components/atoms/BackButton.svelte';
	import FaqButton from '../atoms/FaqButton.svelte';
	import RefreshButton from '../atoms/RefreshButton.svelte';

	export let showBackButton: boolean = true;
	export let showFaqButton: boolean = false;
	export let showRefreshButton: boolean = false;
	export let customBackLink: string = '';
	export let headerTitleColor: string = '#000';
	export let hasCustomBackFunction: boolean = false;
	export let customBackFunction: any;
</script>

<div class="header-box">
	<div class="header-back-btn-box">
		{#if showBackButton}
			<BackButton bind:customBackLink {hasCustomBackFunction} {customBackFunction} />
		{/if}
	</div>
	<div class="header-text-box">
		<h1 class="header" style="color:{headerTitleColor}">
			<slot />
		</h1>
	</div>
	<div class="header-back-btn-box">
		{#if showFaqButton}
			<FaqButton />
		{/if}
		{#if showRefreshButton}
			<RefreshButton isLightIcon={false} />
		{/if}
	</div>
</div>
