<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M17.2614 34.6026C26.6941 34.6026 34.3408 26.9422 34.3408 17.4925C34.3408 8.0428 26.6941 0.382324 17.2614 0.382324C7.82867 0.382324 0.181942 8.0428 0.181942 17.4925C0.181942 26.9422 7.82867 34.6026 17.2614 34.6026Z"
		class="fill-base-100"
	/>
	<path
		d="M16.832 22.5572L11.6831 17.398L16.832 12.2397"
		class="stroke-neutral"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M11.6829 17.3989H22.8428"
		class="stroke-neutral"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>

<style>
	svg {
		height: 40px !important;
		width: 40px !important;
		padding: 0;
		margin: 0;
		background: transparent;
		outline: none;
		cursor: pointer;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
	}
</style>
