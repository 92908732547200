<script lang="ts">
	import { browser } from '$app/environment';
	import { goto } from '$app/navigation';
	import BackButtonIcon from '$lib/icons/BackButtonIcon.svelte';
	export let customBackLink: string;
	export let hasCustomBackFunction: boolean;
	export let customBackFunction: () => void = () => {
		console.error('No action defined!');
	};
	export let previousPage = () => {
		if (customBackLink !== '') {
			browser && goto(customBackLink);
		} else if (hasCustomBackFunction) {
			customBackFunction();
		} else {
			return window.history.back();
		}
	};
</script>

<button on:click={previousPage}>
	<BackButtonIcon />
</button>

<style lang="scss">
	@import '$lib/styles/mixins.scss';
	button {
		@include main-icon-button-size;
		all: unset;
		border-radius: 50%;
		cursor: pointer;

		&:active {
			scale: 0.95;
		}
	}
</style>
