// ZENPASS ROUTING:
export const zenpassRoutes = {
	overview: '/overview',
	getStarted: '/get-started',
	addressGeneral: '/address/general',
	addressProof: '/address/address-proof',
	sendAddressForm: '/address/send',
	personalGeneral: '/personal/general',
	personalId: '/personal/id',
	sendPersonalForm: '/personal/send',
	personalIdBack: '/personal/id-back',
	personalSelfie: '/personal/selfie',
	personalLatin: '/personal/latin',
	zenpassError: '/error?error='
};

export const routeSteps = {
	'/address/general': 1,
	'/address/address-proof': 2,
	'/address/send': 3,
	'/personal/general': 4,
	'/personal/id': 5,
	'/personal/id-back': 6,
	'/personal/send': 7
};

// NFT-TRANSACTION ERROR ROUTING:
export const nftTransactionErrorRoute = '/nft-transaction-error';

// OTHER ROUTES:
export const termsOfServiceRoute = '/terms-of-service';
export const connectionErrorRoute = '/connection-error';
