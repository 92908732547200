// import { nomo } from 'nomo-webon-kit';
import { base } from '$app/paths';
import { browser } from '$app/environment';
import { goto } from '$app/navigation';
import { buttonDisabled } from '$lib/stores/generalStore';
import { tick } from 'svelte';
import { formData } from '$lib/stores/formData';
import { derived, get } from 'svelte/store';

// faq redirect via info button function
export async function handleFaqRedirect() {
	// await nomo.openFAQPage({
	// 	initiallyExpanded: true,
	// 	supportButtonTitle: 'Contact Support',
	// 	supportButtonUrl: 'mailto:support@nomo.app',
	// 	faqContent: zenpassFaq
	// });
}

export const gotoButtonClick = (route: any) => {
	buttonDisabled.set(true);
	browser && goto(base + route);
	buttonDisabled.set(false);
};

//SCROLL INTO VIEW FOR COUNTRY LIST:
export async function scrollToSelectedCountry(className: string) {
	await tick();
	const el: HTMLElement | null = document.querySelector(`.${className}`);
	if (!el) return;
	setTimeout(() => {
		el.scrollIntoView({
			behavior: 'smooth'
		});
	}, 400);
}

//Check if formfields are filled out
export const hasFieldsFilled = (storedData: any, requiredFields: any) => {
	const data = get(storedData);
	if (data) {
		return requiredFields.every((field: any) => data[field] && data[field].trim() !== '');
	}
};

export const fieldsFilled = (storeData: any, requiredFields: any) => {
	return requiredFields.every((field: any) => {
		const value = storeData[field];
		if (typeof value === 'string') {
			return value.trim() !== '';
		} else if (typeof value === 'number') {
			return true; // Consider any number as filled
		} else {
			return !!value; // For other types, consider truthy values as filled
		}
	});
};