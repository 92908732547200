<script lang="ts">
	import { isLoading, selectedTheme } from '$lib/stores/generalStore';
	import LoadingScreen from '$lib/components/atoms/LoadingScreen.svelte';
	import HeaderBox from '$lib/components/molecules/HeaderBox.svelte';
	import Stepper from '../atoms/Stepper.svelte';

	export let showHeaderBackButton: boolean = true;
	export let showHeaderFaqButton: boolean = true;
	export let showHeaderRefreshButton: boolean = false;
	export let headerCustomBackLink: string = '';
	export let headerTitle: string = '';
	export let headerTitleColor: string = '';
	export let showHeaderBox: boolean = true;
	export let showStepperBox: boolean = true;
	export let showButtonBox: boolean = true;
	export let hasCustomBackFunction: boolean = false;
	export let customBackFunction: any;

	$: headerProps = {
		showBackButton: showHeaderBackButton,
		showRefreshButton: showHeaderRefreshButton,
		showFaqButton: showHeaderFaqButton,
		customBackLink: headerCustomBackLink,
		hasCustomBackFunction: hasCustomBackFunction,
		customBackFunction: customBackFunction,
		headerTitleColor: headerTitleColor
	};
</script>

{#if $isLoading}
	<LoadingScreen />
{:else}
	<div class="page">
		{#if showHeaderBox}
			<HeaderBox {...headerProps}>
				{headerTitle}
			</HeaderBox>
		{/if}
		{#if showStepperBox}
			<Stepper />
		{/if}
		<div class="information">
			<slot name="information-content" />
		</div>
		{#if showButtonBox}
			<div class="button-box">
				<slot name="button-content" />
			</div>
		{/if}
	</div>
{/if}

<style lang="scss">
  .page {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .information {
    flex-grow: 1;
    overflow-y: auto;
  }

  .button-box {
    padding: 1rem;
  }
</style>