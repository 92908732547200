<script lang="ts">
	import FaqButtonIcon from '$lib/icons/FaqButtonIcon.svelte';
	import { handleFaqRedirect } from '$lib/utils/helpers';
</script>

<button class="faq-button" on:click={() => handleFaqRedirect()}>
	<FaqButtonIcon />
</button>

<style lang="scss">
	@import '$lib/styles/mixins';
	button {
		@include main-icon-button-size;
		all: unset;
		border-radius: 50%;
		cursor: pointer;
		display: grid;
		box-sizing: border-box;
		place-content: center;
		&:active {
			scale: 0.95;
		}
	}
</style>
