<script lang="ts">
	import refreshIcon from '$lib/assets/refresh.svg';
	import refreshIconLight from '$lib/assets/refreshIconLight.svg';
	export let isLightIcon: boolean;
</script>

<button on:click={() => window.location.reload()} class="refresh-button">
	<img class="refresh-icon" src={isLightIcon ? refreshIconLight : refreshIcon} alt="refresh" />
</button>

<style lang="scss">
	@import '$lib/styles/mixins';

	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	button {
		all: unset;
	}
	.refresh-button {
		@include flex-center;
		@include full-height-width;
		.refresh-icon {
			@include icon-size-small;
			animation: rotate 2s linear infinite;
		}
	}
</style>
